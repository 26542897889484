<template>
  <b-card>
    <b-row class="d-flex align-items-center">
      <b-col sm="2">
        <div
          class="text-center text-sm-left pl-0 pl-sm-2"
        >
          <b-avatar
            v-if="!imgDataUrl"
            variant="primary"
            :text="userInitials(profile)"
            :src="userProfileImage(true)"
            size="7rem"
          />
          <img
            v-else
            :src="imgDataUrl"
            alt="profile_image"
            style="width: 7rem"
          >
        </div>
      </b-col>
      <b-col
        sm="4"
        class="text-center text-sm-left"
      >
        <h3 class="font-weight-bold">
          {{ profile.full_name }}
        </h3>
        <b-badge
          v-for="(role, index) in participantRoles(profile.roles)"
          :key="index"
          variant="primary"
          class="font-weight-normal"
          style="margin-right: 2px"
        >
          {{ role === userRoles.MENTOR ? mentorDisplay : menteeDisplay }}
        </b-badge>
        <b-card-text class="mt-1">
          <feather-icon
            icon="MailIcon"
            size="20"
          />
          {{ profile.email }}
        </b-card-text>
        <b-card-text>
          <feather-icon
            icon="PhoneIcon"
            size="20"
          />
          {{ profile.phone }}
        </b-card-text>
        <b-card-text v-if="teamsIntegration">
          <b-link
            @click="
              onShowMSTeamsModal(
                profile,
                imgDataUrl,
                mentorDisplay,
                menteeDisplay,
                teamsIntegration,
                true
              )
            "
          >
            <aom-icon
              icon="TeamsOutlinedIcon"
              size="20"
            />
            Teams ID: {{ teamsIntegration }}
          </b-link>
        </b-card-text>
        <b-card-text v-if="zoomIntegration">
          <b-link href="#">
            <aom-icon
              icon="ZoomOutlinedIcon"
              size="20"
            />
            Connected
          </b-link>
        </b-card-text>
        <b-card-text v-if="skypeIntegration">
          <b-link
            :href="`skype:${skypeIntegration}?chat`"
          >
            <aom-icon
              icon="SkypeOutlinedIcon"
              size="20"
            />
            Skype ID: {{ skypeIntegration }}
          </b-link>
        </b-card-text>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="12">
        <b-input-group>
          <b-form-input
            id="keyword-search"
            v-model="keywordSearch"
            placeholder="Search"
          />
          <b-input-group-append>
            <b-input-group-text>
              <feather-icon
                icon="SearchIcon"
                size="20"
              />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="mt-4 pl-1">
      <h2>Active Matches ({{ activeMatchesItems.length }})</h2>

      <b-table
        striped
        :items="activeMatchesItems"
        thead-class="d-none"
        tbody-tr-class="d-flex d-sm-table-row flex-column"
      >
        <template #cell(avatar)="props">
          <div class="text-center text-sm-left">
            <b-avatar
              v-if="!props.item.avatar.imgDataUrl"
              variant="primary"
              :text="userInitials(props.item.avatar.user)"
              :src="userProfileImage(false, props.item.avatar.imgDataUrl)"
              size="7rem"
            />
            <img
              v-else
              :src="props.item.avatar.imgDataUrl"
              alt="profile_image"
              style="width: 7rem"
            >
          </div>
        </template>

        <template #cell(info)="props">
          <div class="text-center text-sm-left">
            <h3 class="font-weight-bold">
              {{ props.item.info.name }}
            </h3>
            <b-badge
              variant="primary"
              class="font-weight-normal"
              style="margin-right: 2px"
            >
              {{ props.item.info.role === userRoles.MENTOR ? mentorDisplay : menteeDisplay }}
            </b-badge>
            <b-card-text class="mt-1">
              <p class="mb-0">
                Match Status: {{ props.item.info.matchStatus }}
              </p>
              <p class="mb-0">
                Match Start Date: {{ props.item.info.matchStartDate }}
              </p>
              <p class="mb-0">
                Match End Date: {{ props.item.info.matchEndDate }}
              </p>
            </b-card-text>
            <b-link
              v-if="isAllowedToViewApplication(props.item.info.matchStatusId)"
              :href="getViewApplicationHref(props.item.contacts.userId, props.item.info.roleName)"
              target="_blank"
              @click="onViewApplication({...props.item.contacts, ...props.item.info})"
            >
              View Application
            </b-link>
          </div>
        </template>

        <template #cell(contacts)="props">
          <div class="text-center text-sm-left">
            <b-card-text>
              <b-button
                variant="link"
                class="p-0 d-flex align-items-center mx-auto ml-sm-0"
                @click="sendEmail(props.item.contacts.userId)"
              >
                <feather-icon
                  icon="MailIcon"
                  size="20"
                  style="margin-right:5px"
                />
                {{ props.item.contacts.mail }}
              </b-button>
            </b-card-text>
            <b-card-text>
              <b-link
                :href="'tel:'+props.item.contacts.phone"
              >
                <feather-icon
                  icon="PhoneIcon"
                  size="20"
                />
                {{ props.item.contacts.phone }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.teams">
              <b-link
                @click="
                  onShowMSTeamsModal(
                    {...props.item.info, ...props.item.avatar},
                    props.item.avatar.imgDataUrl,
                    mentorDisplay,
                    menteeDisplay,
                    props.item.contacts.teams
                  )
                "
              >
                <aom-icon
                  icon="TeamsOutlinedIcon"
                  size="20"
                />
                Teams ID: {{ props.item.contacts.teams }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.zoom">
              <b-link
                @click="createZoomMeetingRoom(props.item.contacts.userId)"
              >
                <aom-icon
                  icon="ZoomOutlinedIcon"
                  size="20"
                />
                Zoom
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.skype">
              <b-link
                :href="`skype:${props.item.contacts.skype}?chat`"
              >
                <aom-icon
                  icon="SkypeOutlinedIcon"
                  size="20"
                />
                Skype ID: {{ props.item.contacts.skype }}
              </b-link>
            </b-card-text>
            <b-button
              v-if="isProgramMOD"
              variant="primary"
              @click="onUnMatchModal(props.item)"
              @clear="clearUnMatchModal"
            >
              Unmatch
            </b-button>
          </div>
        </template>
      </b-table>
    </b-row>

    <b-row class="mt-4 pl-1">
      <h2>Match History ({{ matchHistoryItems.length }})</h2>

      <b-table
        striped
        :items="matchHistoryItems"
        thead-class="d-none"
        tbody-tr-class="d-flex d-sm-table-row flex-column"
      >
        <template #cell(avatar)="props">
          <div class="text-center text-sm-left">
            <b-avatar
              v-if="!props.item.avatar.imgDataUrl"
              variant="primary"
              :text="userInitials(props.item.avatar.user)"
              :src="userProfileImage(false, props.item.avatar.imgDataUrl)"
              size="7rem"
            />
            <img
              v-else
              :src="props.item.avatar.imgDataUrl"
              alt="profile_image"
              style="width: 7rem"
            >
          </div>
        </template>

        <template #cell(info)="props">
          <div class="text-center text-sm-left">
            <h3 class="font-weight-bold">
              {{ props.item.info.name }}
            </h3>
            <b-badge
              variant="primary"
              class="font-weight-normal"
              style="margin-right: 2px"
            >
              {{ props.item.info.role === userRoles.MENTOR ? mentorDisplay : menteeDisplay }}
            </b-badge>
            <b-card-text class="mt-1">
              <p class="mb-0">
                Match Status: {{ props.item.info.matchStatus }}
              </p>
              <p class="mb-0">
                Match Start Date: {{ props.item.info.matchStartDate }}
              </p>
              <p class="mb-0">
                Match End Date: {{ props.item.info.matchEndDate }}
              </p>
            </b-card-text>
            <b-link
              v-if="isAllowedToViewApplication(props.item.info.matchStatusId)"
              :href="getViewApplicationHref(props.item.contacts.userId, props.item.info.roleName)"
              target="_blank"
              @click="onViewApplication({...props.item.contacts, ...props.item.info})"
            >
              View Application
            </b-link>
          </div>
        </template>

        <template #cell(contacts)="props">
          <div class="text-center text-sm-left">
            <b-card-text>
              <b-button
                variant="link"
                class="p-0 d-flex align-items-center mx-auto ml-sm-0"
                @click="sendEmail(props.item.contacts.userId)"
              >
                <feather-icon
                  icon="MailIcon"
                  size="20"
                  style="margin-right:5px"
                />
                {{ props.item.contacts.mail }}
              </b-button>
            </b-card-text>
            <b-card-text>
              <b-link
                :href="'tel:'+props.item.contacts.phone"
              >
                <feather-icon
                  icon="PhoneIcon"
                  size="20"
                />
                {{ props.item.contacts.phone }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.teams">
              <b-link
                @click="
                  onShowMSTeamsModal(
                    {...props.item.info, ...props.item.avatar},
                    props.item.avatar.imgDataUrl,
                    mentorDisplay,
                    menteeDisplay,
                    props.item.contacts.teams
                  )
                "
              >
                <aom-icon
                  icon="TeamsOutlinedIcon"
                  size="20"
                />
                Teams ID: {{ props.item.contacts.teams }}
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.zoom">
              <b-link
                @click="createZoomMeetingRoom(props.item.contacts.userId)"
              >
                <aom-icon
                  icon="ZoomOutlinedIcon"
                  size="20"
                />
                Zoom
              </b-link>
            </b-card-text>
            <b-card-text v-if="props.item.contacts.skype">
              <b-link
                :href="`skype:${props.item.contacts.skype}?chat`"
              >
                <aom-icon
                  icon="SkypeOutlinedIcon"
                  size="20"
                />
                Skype ID: {{ props.item.contacts.skype }}
              </b-link>
            </b-card-text>
          </div>
        </template>
      </b-table>
    </b-row>

    <email-compose
      v-if="userId"
      v-model="shallShowEmailComposeModal"
      :user-id="userId"
      :is-program-communications="true"
    />
    <un-match
      ref="unMatch"
      :user="matchedUser"
      :match-id="matchId"
    />

    <ms-teams-modal
      :show="showTeamsModal"
      :profile="userProfile"
      @closeTeamsModal="closeTeamsModal"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BTable,
  BAvatar,
  BBadge,
  BLink,
  BButton
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import matchesService from "@/services/matchesService";
import { userRoles } from "@/models/userRoles";
import { matchStatus, integrations as integrationPlatforms } from "@models";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import EmailCompose from "@/participants/communications/EmailCompose.vue";
import { ref } from '@vue/composition-api';
import _debounce from "lodash/debounce";
import unMatch from '@/participants/mentoring-on-demand/partials/Unmatch.vue';
import MsTeamsModal from "./MsTeamsModal.vue";
import AomIcon from "@aom-core/AomIcon";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BTable,
    BAvatar,
    BBadge,
    BLink,
    BButton,
    EmailCompose,
    unMatch,
    MsTeamsModal,
    AomIcon
  },
  data() {
    return {
      userRoles,
      activeMatchesItems: [],
      matchHistoryItems: [],
      keywordSearch: null,
      imgDataUrl: "",
      userId: 0,
      matchedUser: {},
      matchId: undefined,
      userProfile: null,
      showTeamsModal: false
    };
  },

  computed: {
    ...mapGetters("programs", ["defaultProgramId", "isProgramMOD"]),
    ...mapGetters("profile", ["profile"]),

    mentorDisplay() {
      return this.$store.getters['participants/mentorDisplay'];
    },
    menteeDisplay() {
      return this.$store.getters['participants/menteeDisplay'];
    },
    participantCurrentProgram() {
      return this.$store.getters['participants/program'];
    },
    teamsIntegration() {
      if (this.profile) {
        const teams = this.profile.integrations.find(integration => integration.id === integrationPlatforms.TEAMS);
        if (teams && teams.pivot) { return teams.pivot.data; }
      }
      return null;
    },
    zoomIntegration() {
      if (this.profile) {
        const zoom = this.profile.integrations.find(integration => integration.id === integrationPlatforms.ZOOM);
        if (zoom && zoom.pivot) { return zoom.pivot.access_token; }
      }
      return null;
    },
    skypeIntegration() {
      if (this.profile) {
        const skype = this.profile.integrations.find(integration => integration.id === integrationPlatforms.SKYPE);
        if (skype && skype.pivot) { return skype.pivot.data; }
      }
      return null;
    }
  },

  watch: {
    keywordSearch: _debounce(function(n){
      if (n) {
        this.loadItems({
          columnFilters: [
            this.profile.role === userRoles.MENTOR
              ? { field: "user.0.full_name", value: n }
              : { field: "user.1.full_name", value: n },
          ],
        });
      } else {
        this.loadItems();
      }
    }, 1000)
  },

  created() {
    this.loadItems();
  },

  methods: {
    isAllowedToViewApplication(statusId) {
      return Boolean(statusId === matchStatus.ACTIVE) || Boolean(statusId === matchStatus.COMPLETED);
    },
    getTeamsDetail(user) {
      if (user) {
        const teams = user.integrations.find(integration => integration.id === integrationPlatforms.TEAMS);
        if (teams && teams.pivot) { return teams.pivot.data; }
      }
      return null;
    },
    getZoomDetail(user) {
      if (user) {
        const zoom = user.integrations.find(integration => integration.id === integrationPlatforms.ZOOM);
        if (zoom && zoom.pivot) { return zoom.pivot.access_token; }
      }
      return null;
    },
    getSkypeDetail(user) {
      if (user) {
        const skype = user.integrations.find(integration => integration.id === integrationPlatforms.SKYPE);
        if (skype && skype.pivot) { return skype.pivot.data; }
      }
      return null;
    },
    async loadItems(search = null) {
      const response = await matchesService.listUserMatches(
        this.defaultProgramId,
        this.profile.id,
        search
      );
      this.getActiveMatches(response.data.items);
      this.getMatchHistory(response.data.items);
    },

    getActiveMatches(items) {
      const matches = items.filter(i => i.status_id === matchStatus.ACTIVE);
      const seen = new Set();
      const matcheUsers = matches
        .map(m => m.match_users)
        .flat(1)
        .filter(el => {
          if (el.user_id !== this.profile.id) {
            const duplicate = seen.has(el.user_id);
            seen.add(el.user_id);
            return !duplicate;
          }
        });
      this.activeMatchesItems = matcheUsers.map(u => {
        const match = matches.find(m => m.id === u.match_id);
        if (match) {
          return {
            avatar: {
              imgDataUrl: "",
              user: u.user,
              matchId: match.id
            },
            info: {
              role: u.role_id,
              roleName: u.role.name,
              name: u.user.full_name,
              matchStatus: match.status.name,
              matchStartDate: this.formatDate(match.started_at),
              matchEndDate: this.formatDate(match.ended_at),
              matchStatusId: match.status_id,
            },
            contacts: {
              mail: u.user.email,
              phone: u.user.phone,
              userId: u.user_id,
              teams: this.getTeamsDetail(u.user),
              zoom: this.getZoomDetail(u.user),
              skype: this.getSkypeDetail(u.user),
            },
          };
        }
        return;
      });
    },

    getMatchHistory(items) {
      let matches = items.filter(i => i.status_id === matchStatus.CANCELLED || i.status_id === matchStatus.COMPLETED);
      const seen = new Set();
      const matcheUsers = matches
        .map(m => m.match_users)
        .flat(1)
        .filter(el => {
          if (el.user_id !== this.profile.id) {
            const duplicate = seen.has(el.user_id);
            seen.add(el.user_id);
            return !duplicate;
          }
        });
      this.matchHistoryItems = matcheUsers.map(u => {
        const match = items.find(m => m.id === u.match_id);
        if (match) {
          return {
            avatar: {
              imgDataUrl: "",
              user: u.user,
            },
            info: {
              role: u.role_id,
              roleName: u.role.name,
              name: u.user.full_name,
              matchStatus: match.status.name,
              matchStartDate: this.formatDate(match.started_at),
              matchEndDate: this.formatDate(match.ended_at),
              matchStatusId: match.status_id,
            },
            contacts: {
              mail: u.user.email,
              phone: u.user.phone,
              userId: u.user_id,
              teams: this.getTeamsDetail(u.user),
              zoom: this.getZoomDetail(u.user),
              skype: this.getSkypeDetail(u.user),
            },
          };
        }
        return;
      });
    },

    formatDate(datetime) {
      return localeDateStringFromIsoDateTime(datetime);
    },

    userProfileImage(isMyProfile, profileImage) {
      if (isMyProfile) {
        return this.profile?.profile_image?.url || "";
      }
      return profileImage || "";
    },

    userInitials(userName) {
      const firstInt = Array.from(userName?.first_name)[0];
      const lastInt = Array.from(userName?.last_name)[0];
      return `${firstInt}${lastInt}`;
    },

    participantRoles(roles) {
      if (roles && roles.length) {
        return roles.filter(r => r === userRoles.MENTOR || r === userRoles.MENTEE);
      }
    },

    sendEmail(userId) {
      this.userId = userId;
      this.shallShowEmailComposeModal = true;
    },

    getViewApplicationHref(userId, roleName) {
      const route = this.$router.resolve({
        name: `participant-application-${roleName}-overview`,
        query: { userId: userId },
      });
      return route.href;
    },

    onViewApplication(user) {
      localStorage.setItem('userInfoApplication', JSON.stringify(user));
    },

    async createZoomMeetingRoom(userId) {
      await matchesService.createZoomMeetingRoom(userId);
    },
    onUnMatchModal(args) {
      const { avatar } = args;
      this.$refs.unMatch.show();
      this.matchedUser = avatar.user;
      this.matchId = avatar.matchId;
    },
    clearUnMatchModal() {
      this.matchedUser = {};
      this.matchId = undefined;
    },
    async onShowMSTeamsModal(
      profile,
      imgDataUrl,
      mentorDisplay,
      menteeDisplay,
      teamsContact,
      isMyProfile = false,
    ) {
      const userProfile = {
        ...profile,
        imgDataUrl: imgDataUrl,
        mentorDisplay: mentorDisplay,
        menteeDisplay: menteeDisplay,
        teamsContact: teamsContact,
        isMyProfile: isMyProfile
      };
      this.userProfile = userProfile;
      this.showTeamsModal = true;
    },
    closeTeamsModal() {
      this.userProfile = null;
      this.showTeamsModal = false;
    }
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    return {
      shallShowEmailComposeModal,
    };
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>
