<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-un-match"
      v-model="showModal"
      size="md"
      no-close-on-backdrop
      centered
      cancel-variant="outline-secondary"
      @hidden="onHidden"
    >
      <b-form>
        <b-row class="mb-1">
          <b-col
            sm="12"
            class="mb-3"
          >
            <h5 class="text-center">
              Are You Sure You Want To Unmatch With ?
            </h5>
          </b-col>
          <b-col
            sm="12"
            offset="3"
          >
            <b-row>
              <b-col
                sm="2" 
              >
                <b-avatar
                  class="mr-1"
                  variant="primary"
                  :text="userInitials(user)"
                  :src="userProfileImage(user)" 
                  size="3rem"
                />
              </b-col>
              <b-col
                sm="8"
                class="ml-n2"
              >
                {{ user.full_name }}
                <p>
                  <small
                    class="text-muted text-right text-truncate w-100"
                  >
                    {{ mentorDisplay }}
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
          >
            <p class="text-center">
              You will not be able to <b>Undo</b> this
            </p>
            <validation-provider
              v-slot="validationContext"
              ref="acceptRequestMessage"
              rules="required"
              name="Unmatch Message"
            >
              <b-form-textarea
                id="accept-match-request-message"
                v-model="unMatchMessage"
                placeholder="Explain why you wish to unmatch..."
                rows="6"
              />
              <b-form-invalid-feedback
                :state="validationContext.errors.length === 0"
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>            
      </b-form>
      <template>
        <b-button
          size="md"
          class="mx-auto justify-content-center"
          variant="primary"
          type="submit"
          @click="handleSubmit(unMatchRequest)"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>
            <feather-icon
              class="mr-1"
              icon="SendIcon"
              size="16"
            />Send</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>
            
<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  VBTooltip, 
  BFormTextarea,
  BAvatar,
  BFormInvalidFeedback,
  BSpinner
} from "bootstrap-vue";
import {
  getValidationState
} from "@/libs/utils";
import { mapGetters } from 'vuex';
import { userRoles, matchStatus } from '@models';
import { matchesService } from '@services';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
    
export default {
  name: "UnMatchMoD",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    BFormTextarea,
    BAvatar,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BSpinner
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
    },
    matchId: {
      type:Number,
      default: 0
    }
  },
  data() {
    return {
      showModal: false, 
      isLoading: false,
      unMatchMessage: '',
      isUpdating: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    ...mapGetters('participants', ['mentorDisplay', 'menteeDisplay'])
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.$emit('clear');
    },
    userInitials(user) {
      if(user && user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    async unMatchRequest() {
      if(!this.matchId) {
        return;
      }
      const data = { status_id: matchStatus.CANCELLED };
      try {
        this.isUpdating = true;
        const result = await matchesService.patchUpdateMatch(this.defaultProgramId, this.matchId, data);
        if (result) {
          this.$toast(makeSuccessToast('Match Unpublished successfully.'));
          this.clearForm();
        }
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    }
  },
  setup() {
    return {
      getValidationState,
      userRoles
    };
  },
};
</script>